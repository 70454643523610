import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

class Bottom extends Component {
    state = {
		reload: false
	}

	componentDidMount() {
		this.setState({
			reload: true
		})
	}

    generateSlide = () =>{
		const { bottom_slider } = this.props;
		return bottom_slider.edges.map((data,x)=>
			<div key={x} className="img">
    			<img src={data.node.image} alt="" className="img-fluid"/>
    		</div>
		)
    }
    
    render() {
        return (
            <>
                <img src={require('assets/img/homepage/Sub-Section_Fitur.png')} alt=""/>
                <div className="banner_slide">
                    <div className="head_title">
                        <p>Fitur</p>
                    </div>
                    <div className="desktop">
                        <Carousel 
                            infiniteLoop={true}
                            centerMode={true}
                            showIndicators={true}
                            showStatus={false}
                            showThumbs={false}
                            transitionTime={500}
                            centerSlidePercentage={85}
                        >
                            {this.generateSlide()}
                        </Carousel>
                    </div>
                    <div className="mobile">
                        <Carousel 
                            infiniteLoop={true}
                            showIndicators={true}
                            showStatus={false}
                            showThumbs={false}
                            transitionTime={500}
                        >
                            {this.generateSlide()}
                        </Carousel>
                    </div>
                    
                </div>
            </>
        );
    }
}

export default Bottom;