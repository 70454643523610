import React, { Component } from 'react';
import {navigate} from 'gatsby';
import Top from "./Top";
import Middle from "./Middle";
import Bottom from "./Bottom";

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as tabActions from 'redux/actions/tab';
import * as modalActions from 'redux/actions/modal';

class Home extends Component {

	
	generateChar = () => {
		const {char} = this.props;
		return char.edges.map((data,x)=>
			<div className="char__list" key={x} onClick={() => navigate(`/guide/character/?c=${data.node.directusId}`)}>
				<img src={data.node.home_thumbnail ? data.node.home_thumbnail : "https://dummyimage.com/219x133/fff.jpg&text=Image+Not+Found"} alt="" className="img-fluid"/>
			</div>
		)
	}

    render() {
		const { settings, top_slider, tab, tabActions, all, event, update, modalActions } = this.props;
        return (
            <div id="Home">
            	<Top actions={modalActions} {...settings.edges[0].node} />
            	<div className="bottom">
            		<div className="bottom__cont">
						<img src={require('assets/img/homepage/Sub-Section_Beritaterbaru.png')} alt=""/>
						<Middle 
							tab={tab} 
							tabActions={tabActions} 
							slider={top_slider.edges} 
							all={all.edges} 
							update={update.edges} 
							event={event.edges} 
						/>
	            		<div className="char">
			    			{this.generateChar()}
			        	</div>
						<Bottom {...this.state} {...this.props} />						
            		</div>
            	</div>
            </div>
        );
    }
}

function mapStateToProps(state) {
	return { tab: state.tab }
}

function mapDispatchToProps(dispatch) {
	return {
		modalActions: bindActionCreators(modalActions, dispatch),
		tabActions: bindActionCreators(tabActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);

