import React, { Component } from 'react';
import moment from 'moment';
import { Link } from "gatsby";
import Slider from "react-slick";
import config from 'data/SiteConfig';

class Middle extends Component {

    changeTab = (type) => {
        this.props.tabActions.change_news_tab(type);
    }

    generateTab = (home) =>{
        return config.newsTab.map((data,x)=>
            <div key={x} className={(home.tab === data) ? `active` : ``} onClick={() => this.changeTab(data)}>
                <span>{data}</span>
            </div>
        )
    }

    generateTabContent = (home) =>{
        return config.newsTab.map((data,x)=>
            <ul key={x} className={(home.tab === data) ? `active` : ``}>
                {
                    this.props[data].map((datas, y) => {
                        let tag;

                        if (data === "all") {
                            tag = datas.node.type.data.name;
                        }
                        else
                        {
                            tag = data;
                        }

                        return (
                            <li key={y}>
                                <Link to={`/post/${datas.node.slug}`}>
                                    <span className="tag">{tag}</span>
                                    <span className="title">{datas.node.title}</span>
                                    <span className="date">{moment(datas.node.date).format('DD/MM')}</span>
                                </Link>
                            </li>
                        )
                    })
                }
                <li className="see_all">
                    <Link to={`/news/${data}`}>
                        Lihat Semua &gt;&gt;
                    </Link>
                </li>
            </ul>
        )
    }

    generateSlide = () =>{
        const {slider} = this.props;
        return slider.map((data,x)=>
            <a key={x} href={data.node.link} className="banner">
                <img src={data.node.image} alt="" className="img-fluid"/>
            </a>
        )
    }

    render() {
        const { tab, slider } = this.props;
        const settings = {
            customPaging: function(i) {
                if(typeof window !== 'undefined') {
                    if(window.screen.width > 820){
                        return (
                            <a>
                                {slider[i].node.name}
                            </a>
                        )
                    }
                    else
                    {
                        return(
                            <div className="btn">
                            </div>
                        )
                    }
                }
                else
                {
                    return(
                        <div className="btn">
                        </div>
                    )
                }
            },
            dots: true,
            dotsClass: "slick-dots slick-thumb",
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false
        };

        return (
            <div className="home_news">         
                <div className="slide">
                    <div className="head_title">
                        <p>Berita Terbaru</p>
                    </div>
                    
                    <Slider {...settings}>
                        {this.generateSlide()}
                    </Slider>
                </div>
                <div className="news">
                    <div className="tab_title">
                        {this.generateTab(tab)}
                    </div>
                    <div className="tab_content">
                        {this.generateTabContent(tab)}
                    </div>
                </div>
            </div>
        );
    }
}

export default Middle;