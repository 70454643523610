import React, { Component } from 'react';
import {graphql} from 'gatsby';

class Top extends Component {
	render() {
		const {playstore, appstore, actions, banner} = this.props;
        return (
        	<div className="top" style={{backgroundImage: `url(${banner})`}}>
        		<div className="cont_top">
            		<div className="btn_vid" onClick={()=>actions.toggle_modal('video',true)}>
            			<img src={require('assets/img/homepage/Button_Play_Video_Loop.gif')} alt="" className="img-fluid"/>
            		</div>
            		<div className="tagline">
            			<img src={require('assets/img/homepage/Tagline_CODM.png')} alt="" className="img-fluid"/>
            		</div>
            		<ul>
            			<li>
	            			<a href={playstore} target="_blank">
	            				<img src={require('assets/img/homepage/Button_GP.png')} alt="" className="img-fluid"/>
	            			</a>
	            		</li>
            			<li>
            				<a href={appstore} target="_blank">
            					<img src={require('assets/img/homepage/Button_IOS.png')} alt="" className="img-fluid"/>
            				</a>
            			</li>
            		</ul>
        		</div>
        	</div>
        );
    }
}

export default Top;

