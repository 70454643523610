import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "layout";
import config from "data/SiteConfig";
import Home from "components/Home";

class Index extends React.Component {
	render() {
		const {data} = this.props;
		return (
			<Layout>
				<div className="index-container">
					<Helmet title={"Home - " + config.siteTitle} />
					<Home {...data} />
				</div>
			</Layout>
		);
	}
}

export default Index;

/* eslint no-undef: "off" */
export const homeQuery = graphql`
query Home {
	settings: allDirectusSetting {
	  edges {
		node {
		  facebook
		  instagram
		  youtube
		  playstore
		  appstore
		  banner
		  video
		}
	  }
	}
	all: allDirectusPost(sort: {fields: directusId, order: DESC}, limit: 6) {
	  edges {
		node {
		  type {
			data {
			  name
			}
		  }
		  title
		  slug
		  date
		}
	  }
	}
	event: allDirectusPost(sort: {fields: directusId, order: DESC}, limit: 6, filter: {type: {data: {id: {eq: 1}}}}) {
	  edges {
		node {
		  title
		  slug
		  date
		}
	  }
	}
	update: allDirectusPost(sort: {fields: directusId, order: DESC}, limit: 6, filter: {type: {data: {id: {eq: 2}}}}) {
	  edges {
		node {
		  title
		  slug
		  date
		}
	  }
	}
	top_slider: allDirectusTopSlider {
	  edges {
		node {
		  name
		  image
		  link
		}
	  }
	}
	bottom_slider: allDirectusBottomSlider {
	  edges {
		node {
		  image
		}
	  }
	}
	char: allDirectusCharacter(sort: {fields: sort, order: ASC}, limit: 4) {
		edges {
		  node {
			directusId
			home_thumbnail
			sort
		  }
		}
  	}
  }
  
`